import CommonPage from "../Common/CommonPage";
import AboutComponent from "./AboutComponent";

export default function About() {
    let main;
    main = (
      <AboutComponent/>
    );
    return(
        <CommonPage main={main}/>
    );
}
