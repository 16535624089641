import * as React from 'react';
import { Card, CardActions, CardContent, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import VersionComponent from './VersionComponent';
import CommonPage from '../Common/CommonPage';

export default function VersionPage() {
  let main;
  main = (
    <VersionComponent/>
  );
  return (
    <CommonPage main={main}/>
  );
}