import * as React from 'react';
import Introduction from './Home/Introduction';
import CommonPage from './Common/CommonPage';
import Footer from './Footer/Footer';


export default function LandingPage() {
  let main;
  main = (
    <>
    <Introduction/>
    <Footer/>
    </>
  );
  return (
   <CommonPage main={main}/>
  );
}