import { Box, Container, Stack, Typography, alpha } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import VersionElement from "./VersionElement";
import Footer from "../Footer/Footer";

export default function VersionComponent() {
    return(
        <Box
            id="hero"
            sx={(theme) => ({
                width: '100%',
                backgroundImage:
                theme.palette.mode === 'light'
                    ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                    : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
                backgroundSize: '100% 20%',
                backgroundRepeat: 'no-repeat',
            })}
        >
      <VersionElement/>
      <Footer/>
    </Box>
    );
}