import { Box, Card, CardContent, Pagination, Typography, CircularProgress, Container } from "@mui/material";
import { useEffect, useState } from "react";
import IVersion from "../Interfaces/IVersion";
import { Link } from "react-router-dom";
import convertDate from "../Helpers/convertDate";

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

export default function VersionsCard() {
    const [versions, setVersion] = useState<IVersion[]>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setCountPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true); // Добавлено состояние для отслеживания загрузки данных
    const apiUrl = process.env.REACT_APP_API_URL;

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        const fetchGetAll = async () => {
            setIsLoading(true); // Устанавливаем состояние загрузки перед началом запроса
            fetch(`${apiUrl}/api/Version/GetAll`, {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    pagging: { page: page, count: 18 }
                })
            }).then(res => res.json())
                .then(res => {
                    const sortedVersions = res.versions.sort((a: any, b: any) => {
                        return new Date(b.date).getTime() - new Date(a.date).getTime();
                    });
                    setVersion(sortedVersions);
                    setCountPages(res.recordsCount);
                    setIsLoading(false); // Снимаем состояние загрузки после завершения запроса
                });
        };

        fetchGetAll();
    }, [page]);

    if (isLoading) {
        return (
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 14, sm: 20 },
                    pb: { xs: 8, sm: 12 },
                }}
            >
                <CircularProgress />
            </Container>
        );
    }

    return (
        <div>
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '20px',
                justifyContent: 'center',
                padding: '20px'
            }}>
                {versions.map((version) => {
                    const highlightedName = version.name.includes('[r]')
                        ? version.name.replace('[r]', '').replace('[/r]', '')
                        : version.name;

                    return (
                        <Link to={`/versions-bsg/${version.versionId}`} key={version.versionId} style={{ textDecoration: 'none' }}>
                            <Card sx={{ minWidth: 275, cursor: 'pointer' }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Version
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        {bull}
                                        {version.name.includes('[r]') ? (
                                            <span style={{ color: '#FF8C00' }}>
                                                {highlightedName}
                                            </span>
                                        ) : (
                                            highlightedName
                                        )}
                                        {bull}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        for {version.compatibility}
                                    </Typography>
                                    <Typography variant="body2">
                                        Release Date: {convertDate(version.date)}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {version.shortInfo}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    );
                })}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination
                    count={Math.ceil(totalPages / 18)}
                    page={page}
                    siblingCount={0}
                    onChange={handlePageChange}
                />
            </div>
        </div>
    );
}
