import CommonPage from "../Common/CommonPage";
import ContactComponent from "./ContactComponent";

export default function Contact() {
    let main;
    main = (
        <ContactComponent/>
    );
    return(
        <CommonPage main={main}/>
    );
}